<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="client-location-modal"
			v-model="isOpen"
			size="lg"
			:ok-title="FormMSG(2, 'Save')"
			:cancel-title="FormMSG(3, 'Cancel')"
			@ok="save"
			@cancel="onCancel"
			@hidden="onCancel"
			cancel-variant="light"
			no-close-on-backdrop
		>
			<template #modal-title>
				<div v-html="modalTitle" />
			</template>
			<div>
				<client-location-form :item="location" @client-location-form-address:updated="locationUpdated" />
			</div>
		</b-modal>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import ClientLocationForm from '@/components/ClientLocation/Form';
import { mapActions } from 'vuex';
import { objReducer } from '@/shared/utils';

export default {
	name: 'ClientLocationModal',
	mixins: [languageMessages],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		location: {
			type: Object,
			required: true,
			default: null
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		modalTitle() {
			return this.FormMSG(1, 'Update location') + `: &laquo; ${this.location.setName} &raquo;`;
		}
	},
	components: {
		ClientLocationForm
	},
	data() {
		return {
			erreur: {},
			warning: ''
		};
	},
	methods: {
		...mapActions({
			updateLocation: 'locations/updateLocation'
		}),
		async save() {
			// console.log('Do Save ...', this.location);
			await this.updateLocation({
				id: this.location.id,
				location: objReducer(['description', 'selected', 'setAddressId', 'setName', 'type', 'note'], this.location)
			});

			this.$toast({
				message: this.FormMSG(4, 'Location updated successfully'),
				title: this.FormMSG(5, 'Success'),
				variant: 'success'
			});
		},
		onCancel() {
			this.$emit('client-location-modal:cancel', false);
		},
		locationUpdated(payload) {
			// console.log('locationUpdated: ', payload);
			this.location.setAddressId = payload.id;
			this.location.setAddress = payload.address;
		}
	}
};
</script>
