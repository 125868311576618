<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div>
				<card-list-builder v-if="$screen.width < 992" :items="items" :fields="locationMobileFields">
					<template slot="actions" slot-scope="data">
						<b-button variant="primary" size="sm" @click="editLocation(data.item)" class="mr-1 btn bg-transparent border-0">
							<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
						</b-button>
						<b-button variant="danger" size="sm" @click="removeLocation(data.item.id)" class="btn bg-transparent border-0">
							<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
						</b-button>
					</template>
				</card-list-builder>
				<b-table
					v-if="$screen.width >= 992"
					:hover="hover"
					responsive="sm"
					ref="clientLocationTable"
					:items="items"
					:fields="locationFields"
					:current-page="currentPage"
					:per-page="perPage"
					:head-variant="hv"
					bordered
					small
					:empty-text="FormMSG(1, 'No location found')"
					show-empty
				>
					<template v-slot:cell(edit)="data">
						<b-button variant="primary" size="sm" @click="editLocation(data.item)" class="btn bg-transparent border-0">
							<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
						</b-button>
					</template>
					<template v-slot:cell(delete)="data">
						<b-button variant="danger" size="sm" @click="removeLocation(data.item.id)" class="btn bg-transparent border-0">
							<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
						</b-button>
					</template>
				</b-table>
				<client-location-modal
					v-model="isOpenLocationModal"
					:location="location"
					@client-location-modal:cancel="onLocationCanceled"
					@client-location-modal:updated="onLocationUpdated"
				/>
			</div>
		</transition>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { addressFormatter } from '@/shared/fomaters';
import { mapActions } from 'vuex';
import ClientLocationModal from '@/components/ClientLocation/Modal';
import { store } from '@/store';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ClientLocationList',
	mixins: [languageMessages, globalMixin],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	computed: {
		baseFields() {
			return [
				{
					key: 'setName',
					label: this.FormMSG(2, 'Name'),
					sortable: true
				},
				{
					key: 'setAddress',
					label: this.FormMSG(6, 'Address'),
					formatter: (v) => {
						return addressFormatter(v);
					},
					sortable: true
				},
				{
					key: 'type',
					label: this.FormMSG(3, 'Type'),
					formatter: (value) => {
						const findIndex = this.types.findIndex((s) => s.value === value);
						return findIndex !== -1 ? this.types[findIndex].text : '';
					},
					class: 'text-center',
					sortable: true
				},
				{
					key: 'edit',
					label: this.FormMSG(4, 'Edit'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				},
				{
					key: 'delete',
					label: this.FormMSG(5, 'Remove'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				}
			];
		},
		locationFields() {
			return this.baseFields;
		},
		locationMobileFields() {
			const keys = ['edit', 'delete'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		}
	},
	components: {
		ClientLocationModal
	},
	data() {
		return {
			erreur: {},
			warning: '',
			hv: 'dark',
			perPage: 0,
			currentPage: 1,
			hover: true,
			locations: [],
			location: {},
			isOpenLocationModal: false,
			types: []
		};
	},
	async created() {
		await this.$nextTick(() => {
			setTimeout(() => {
				if (store.isFilm()) {
					this.types = this.FormMenu(1026);
				} else if (store.isPme()) {
					this.types = this.FormMenu(1322);
				} else if (store.isAgency()) {
					this.types = this.FormMenu(1323);
				}
			}, 150);
		});
	},
	mounted() {
		this.$nextTick(() => {
			this.locations = this.items;
		});
	},
	methods: {
		...mapActions({
			deleteLocation: 'locations/deleteLocation',
			setCurrentLocation: 'locations/setCurrentLocation',
			destroyCurrentLocation: 'locations/destroyCurrentLocation'
		}),
		editLocation(item) {
			this.location = item;
			this.isOpenLocationModal = true;
			// console.log('edit-location: ', item);
		},
		removeLocation(id) {
			try {
				this.destroyCurrentLocation();
				this.deleteLocation(id);
				// this.locations.splice(this.locations.findIndex((location) => parseInt(location.id, 10) === parseInt(id, 10)), 1);
				this.items.splice(
					this.items.findIndex((location) => parseInt(location.id, 10) === parseInt(id, 10)),
					1
				);
				this.$toast({
					message: this.FormMSG(7, 'Location removed successfully'),
					title: this.FormMSG(8, 'Success'),
					variant: 'success'
				});
				this.$emit('client-location:removed', id);
			} catch (e) {
				console.log({ e });
			}
		},
		onLocationCanceled() {
			this.isOpenLocationModal = false;
		},
		onLocationUpdated(payload) {}
	}
};
</script>
